import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VCard,{staticClass:"w-100"},[_c('h3',{staticClass:"card__title"},[_vm._v("Glosario de Términos")]),_c(VRow,{staticClass:"m-0",attrs:{"justify":"space-between"}},[_c('div',{staticClass:"col-12 col-sm-5"},[_c(VTreeview,{attrs:{"active":_vm.active,"items":_vm.items,"open":_vm.open,"activatable":"","color":"#009ada","open-on-click":"","shaped":"","transition":"","hoverable":"","dense":"","return-object":""},on:{"update:active":[function($event){_vm.active=$event},function($event){return _vm.getSelectedWord($event)}],"update:open":[function($event){_vm.open=$event},function($event){return _vm.handleOpen($event)}]}}),_c('div',{staticClass:"col-12 p-0 button-glosary",staticStyle:{"display":"flex","justify-content":"flex-end"}})],1),_c(VDivider,{attrs:{"vertical":""}}),_c('div',{staticClass:"col-12 col-sm-7 d-flex align-items-center text-center"},[_c(VScrollYTransition,{attrs:{"mode":"out-in"}},[(!_vm.selected.id)?_c('div',{staticClass:"text-h6 grey--text text--lighten-1 font-weight-light",staticStyle:{"align-self":"center"}},[_vm._v(" Seleciona un Término del Glosario ")]):_c(VCard,{key:_vm.selected.id,staticClass:"d-flex mx-auto",attrs:{"flat":""}},[_c(VCardText,{staticClass:"d-flex align-items-center text-center",staticStyle:{"margin-bottom":"30px"}},[_c('div',[_c('h3',{staticClass:"content__title mb-2"},[_vm._v(_vm._s(_vm.selected.title))]),_c('p',{staticClass:"content__text mb-2",domProps:{"innerHTML":_vm._s(_vm.selected.shortdesc)}})])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }