<template>
  <div class="container">
    <v-row no-gutters>
      <div class="col-12 text-center mb-3">
        <h3 class="text-center m-0">{{ title }}</h3>
      </div>
    </v-row>
    <!-- Categories Carousel -->

    <!-- Item -->
    <v-skeleton-loader
      :boilerplate="boilerplateActive"
      v-if="companies.length == 0"
      class="mx-auto"
      type="image"
    ></v-skeleton-loader>

    <slick :key="index" :options="slickOptions" v-else>
      <template v-for="company in companies" style="height: inherit">
        <div class="fw-carousel-item slide"  :key="company.id">
          <router-link :to="'/empresa/' + company.link">
            <div class="category-box-container">
              <div class="category-box">
                <div class="category-box-content">
                  <div class="icon-title text-center">
                    <v-avatar size="80" style="background-color: #fff">
                      <img :src="company.logo.route" :alt="company.name" />
                    </v-avatar>
                    <h3>{{ company.name }}</h3>
                  </div>
                </div>
                <div
                  class="category-box-background"
                  :style="{
                    'background-image': 'url(' + company.cover + ')',
                  }"
                ></div>
                <!-- <img :src="item.image"> -->
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </slick>
  </div>
  <!-- Categories Carousel / End -->
</template>

<script>
  import Slick from 'vue-slick'

  export default {
    props: ['title', 'desc', 'data', 'type', 'master', 'country'],
    data() {
      return {
        index: 0,
        intervalFunction: '',
        companies: [],
        boilerplateActive: false,
      }
    },
    components: {
      Slick,
    },
    watch: {
      country(newValue, oldValue) {
        this.getCompanies()
      },
    },
    created() {
      this.getCompanies()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      adsTimer() {
        this.intervalFunction = setInterval(this.getCompanies, 60000)
      },
      async getCompanies() {
        let countryStr = ''

        let outstangingStr = ''
        let masterStr = ''
        if (this.country && this.country.id) {
          countryStr = '&country_id=' + this.country.id
        }
        if (this.master) {
          masterStr = '&master=1'
        }
        if (this.outstanding && this.outstanding == 1) {
          outstangingStr = '&outstanding=1'
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-list?random=1&limit=5&active=1&type=' +
              this.type +
              countryStr +
              outstangingStr +
              masterStr
          )
          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
            }
            let cover = []
            let smallLogo = []
            this.companies.map((company) => {
              if (company.uploads) {
                cover = company.uploads.filter((file) => file.type == 'cover')
                if (cover.length > 0) {
                  company.cover = cover.shift().route
                } else {
                  company.cover = ''
                }
                smallLogo = company.uploads.filter((file) => file.type == 'small-logo')
                if (smallLogo.length > 0) {
                  company.smallLogo = smallLogo.shift().route
                } else {
                  company.smallLogo = ''
                }
              } else {
                company.cover = ''
                company.smallLogo = ''
              }
            })
            this.loading = false
            this.index++
          })
      },
    },
    computed: {
      slickOptions() {
        return {
          centerMode: false,
          centerPadding: '0%',
          slidesToShow: 3,
          dots: true,
          arrows: false,
          responsive: [
            {
              breakpoint: 1441,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                centerPadding: '0px',
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerPadding: '0px',
                slidesToShow: 2,
              },
            },
          ],
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },
  }
</script>
<style lang="scss" scoped>
  .fullwidth-slick-carousel .slick-slide {
    pointer-events: all;
    height: inherit;
  }
  .slick-list,
  .slick-slider,
  .fullwidth-carousel-container,
  .category-box-container .category-box {
    height: inherit;
  }
  .slide {
    margin: 0 10px;
    padding: 10px;
  }
  .fw-carousel-item {
    position: relative;
    height: 270px;
    width: 270px;
    width: 100%;
    max-width: 100%;
  }
  .category-box:hover:before {
    opacity: 0.6 !important;
    background: linear-gradient(-20deg, #003a56, #009ada) !important;
  }
</style>
