import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c(VRow,{attrs:{"no-gutters":""}},[_c('div',{staticClass:"col-12 text-center mb-3"},[_c('h3',{staticClass:"text-center m-0"},[_vm._v(_vm._s(_vm.title))])])]),(_vm.companies.length == 0)?_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"boilerplate":_vm.boilerplateActive,"type":"image"}}):_c('slick',{key:_vm.index,attrs:{"options":_vm.slickOptions}},[_vm._l((_vm.companies),function(company){return [_c('div',{key:company.id,staticClass:"fw-carousel-item slide"},[_c('router-link',{attrs:{"to":'/empresa/' + company.link}},[_c('div',{staticClass:"category-box-container"},[_c('div',{staticClass:"category-box"},[_c('div',{staticClass:"category-box-content"},[_c('div',{staticClass:"icon-title text-center"},[_c(VAvatar,{staticStyle:{"background-color":"#fff"},attrs:{"size":"80"}},[_c('img',{attrs:{"src":company.logo.route,"alt":company.name}})]),_c('h3',[_vm._v(_vm._s(company.name))])],1)]),_c('div',{staticClass:"category-box-background",style:({
                  'background-image': 'url(' + company.cover + ')',
                })})])])])],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }