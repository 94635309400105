import { render, staticRenderFns } from "./HomeCogBanner.vue?vue&type=template&id=cea70150&scoped=true"
var script = {}
import style0 from "./HomeCogBanner.vue?vue&type=style&index=0&id=cea70150&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cea70150",
  null
  
)

export default component.exports