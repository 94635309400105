import { render, staticRenderFns } from "./Navegation.vue?vue&type=template&id=31300635&scoped=true"
import script from "./Navegation.vue?vue&type=script&lang=js"
export * from "./Navegation.vue?vue&type=script&lang=js"
import style0 from "./Navegation.vue?vue&type=style&index=0&id=31300635&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31300635",
  null
  
)

export default component.exports