<template>
	<slick :options="slickOptions" class="flags-scroll">
		<div class="fw-carousel-item slide" v-for="flag in flags" :key="flag.id">
			<v-hover>
				<template v-slot:default="{ hover }">
					<div class="category-box-container text-center">
						<div class="flag-box">
							<img :src="flag.path" style="height: 100px; margin: auto" />
							<v-fade-transition>
								<v-overlay v-if="hover" absolute :opacity="0.8">
									<div class="flag-box-content">
										<v-icon dark small :color="'#fff'" style="margin-right: 5px"> mdi-map-marker </v-icon>
										<span color="fff" v-if="flag.address">{{ flag.address }}</span>
										<v-icon dark small :color="'#fff'" style="margin-right: 5px"> mdi-email-check </v-icon>
										<span v-html="flag.email" v-if="flag.email"></span>
										<v-icon dark small :color="'#fff'" style="margin-right: 5px"> mdi-cellphone </v-icon>
										<span v-if="flag.phone">{{ flag.phone }}</span>
									</div>
								</v-overlay>
							</v-fade-transition>
						</div>
					</div>
				</template>
			</v-hover>
		</div>
	</slick>
</template>

<script>
	import Slick from 'vue-slick'
	export default {
		components: {
			Slick
		},
		data() {
			return {
				flags: [
					{
						id: 1,
						name: 'Chile',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/548575240e115ec89802e40ca91076af.png',
						address: 'Santiago, Chile',
						email: '<a href="mailto:agonzalez@cogfranquicias.com">agonzalez@cogfranquicias.com</a>',
						phone: '+56 9 8259 4921'
					},
					{
						id: 2,
						name: 'Colombia',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/9c67e5cb746f4c772c8d01dfc1ac5c4a.svg',
						address: 'Medellín, Colombia',
						email: '<a href="mailto:redigo.pernia@emprendomas.com">redigo.pernia@emprendomas.com</a>',
						phone: '+57 3052602000'
					},
					{
						id: 3,
						name: 'Costa Rica',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/a83436b69c7f3c0eca340af505f6494d.svg',
						address: 'Costa Rica',
						email: '<a href="mailto:lilvertovar@gmail.com">lilvertovar@gmail.com</a>',
						phone: '+506 729 21050'
					},
					{
						id: 4,
						name: 'Ecuador',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/7967d055e0a684d18a1a6446c2a0ac37.svg',
						address: 'Quito, Ecuador.'
						// email:
						//   '<a href="mailto:o.cadena@grupomantra.com">o.cadena@grupomantra.com</a>',
						// phone: '+593 98 703 9335',
					},
					{
						id: 5,
						name: 'España',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d0a5cfe32d15e6b099ee2ee0e575a032.svg',
						address: 'Málaga, España.',
						email: '<a href="mailto:aberajam@gmail.com">aberajam@gmail.com</a>',
						phone: '+34 666 71 50 82'
					},
					{
						id: 6,
						name: 'Perú',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/6a44dfb56ac96544d8cb0d7960a4f04d.svg',
						address: 'Lima, Perú.',
						email: '<a href="mailto:businesssaverscg@gmail.com">businesssaverscg@gmail.com</a>',
						phone: '+51 918 479 860'
					},
					{
						id: 7,
						name: 'República Dominicana',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/f6a88362e7d54065c00bf2a5a32953fd.svg',
						address: 'Republica Dominicana',
						email: '<a href="mailto:info@xpofranchise.com">info@xpofranchise.com</a>',
						phone: '+34 624 066 406'
					},
					{
						id: 8,
						name: 'Venezuela',
						path: 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/b216adaf346c55d0f3e91a910dcb0908.svg',
						address: 'Venezuela.',
						email: '<a href="mailto:info@xpofranchise.com">info@xpofranchise.com</a>',
						phone: '+34 624 066 406'
					}
				],
				slickOptions: {
					dots: false,
					infinite: true,
					autoplay: false,
					centerMode: false,
					centerPadding: '0px',
					slidesToShow: 7,
					slidesToScroll: 1,
					arrows: false,
					variableWidth: false,
					responsive: [
						{
							breakpoint: 1500,
							settings: {
								centerPadding: '0px',
								slidesToShow: 6,
								slidesToScroll: 6
							}
						},
						{
							breakpoint: 1300,
							settings: {
								centerPadding: '0px',
								slidesToShow: 5,
								slidesToScroll: 5
							}
						},
						{
							breakpoint: 1100,
							settings: {
								centerPadding: '0px',
								slidesToShow: 4,
								slidesToScroll: 4
							}
						},
						{
							breakpoint: 850,
							settings: {
								centerPadding: '0px',
								slidesToShow: 3,
								slidesToScroll: 3
							}
						},
						{
							breakpoint: 650,
							settings: {
								centerPadding: '00px',
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 430,
							settings: {
								centerPadding: '00px',
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				}
			}
		}
	}
</script>
<style lang="scss">
	.flags-scroll {
		.slick-slider,
		.slick-slide > div,
		.slick-list,
		.slick-track,
		.category-box-container,
		.flag-box {
			height: 200px;
		}
		.flag-box {
			justify-content: center;
			align-items: center;
			display: flex;
			background: transparent;
		}
		.v-overlay__scrim {
			border-radius: 5px;
		}
		.slick-slide {
			text-align: center;
			width: 15%;
		}
		.slick-slide:hover {
			// transform: scale3d(1.1, 1.1, 1.1);
			transition: 0.5s linear;
		}
		.flag-box:hover {
			opacity: 1;
		}
		.flag-box:hover:before {
			opacity: 0.7 !important;

			background: linear-gradient(-20deg, #000000, #000000) !important;
		}
		.flag-box-content span {
			font-size: 11px !important;
			padding: 0px;
			display: grid;
		}
		div span a {
			color: #6fa8e0;
		}
	}
</style>
